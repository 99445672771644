<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="listData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="material_no">Material No</vs-th>
        <vs-th sort-key="material_description">Material Description</vs-th>
        <vs-th sort-key="plant">Plant</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="bun">BUN</vs-th>
        <vs-th sort-key="division">Division</vs-th>
        <vs-th sort-key="division_descr">Division Descr</vs-th>
        <vs-th sort-key="product_hierarchy">Product Hierarchy</vs-th>
        <vs-th sort-key="product_hierarchy_descr">Product Hierarchy Descr</vs-th>
        <vs-th sort-key="batch">Batch</vs-th>
        <vs-th sort-key="sales_order">Sales Order</vs-th>
        <vs-th sort-key="sales_document_item">Sales Document Item</vs-th>
        <vs-th sort-key="stock">Stock</vs-th>
        <vs-th sort-key="map_value">MAP Value</vs-th>
        <vs-th sort-key="total_value">Total Value</vs-th>
        <vs-th sort-key="currency">Currency</vs-th>
        <vs-th sort-key="less_than_16_days">{{ table_amount_header }}</vs-th>
        <vs-th sort-key="period_1_amount">Period 1 Amount</vs-th>
        <vs-th sort-key="between_16_and_30_days">16 - 30 days</vs-th>
        <vs-th sort-key="period_2_amount">Period 2 Amount</vs-th>
        <vs-th sort-key="between_31_and_60_days">31 - 60 days</vs-th>
        <vs-th sort-key="period_3_amount">Period 3 Amount</vs-th>
        <vs-th sort-key="between_61_and_90_days">61 - 90 days </vs-th>
        <vs-th sort-key="period_4_amount">Period 4 Amount</vs-th>
        <vs-th sort-key="between_91_and_120_days">91 - 200 days </vs-th>
        <vs-th sort-key="period_5_amount">Period 5 Amount</vs-th>
        <vs-th sort-key="between_121_and_150_days">121 - 150 days </vs-th>
        <vs-th sort-key="period_6_amount">Period 6 Amount</vs-th>
        <vs-th sort-key="greater_than_150_days"> > 150 days </vs-th>
        <vs-th sort-key="period_7_amount">Period 7 Amount</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].material_no">
            {{ data[indextr].material_no }}
          </vs-td>
          <vs-td :data="data[indextr].material_description">
            {{ data[indextr].material_description }}
          </vs-td>
          <vs-td :data="data[indextr].plant">
            {{ data[indextr].plant }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td :data="data[indextr].bun">
            {{ data[indextr].bun }}
          </vs-td>
          <vs-td :data="data[indextr].division">
            {{ data[indextr].division }}
          </vs-td>
          <vs-td :data="data[indextr].division_descr">
            {{ data[indextr].division_descr }}
          </vs-td>
          <vs-td :data="data[indextr].product_hierarchy">
            {{ data[indextr].product_hierarchy }}
          </vs-td>
          <vs-td :data="data[indextr].product_hierarchy_descr">
            {{ data[indextr].product_hierarchy_descr }}
          </vs-td>
          <vs-td :data="data[indextr].batch">
            {{ data[indextr].batch }}
          </vs-td>
          <vs-td :data="data[indextr].sales_order">
            {{ data[indextr].sales_order }}
          </vs-td>
          <vs-td :data="data[indextr].sales_document_item">
            {{ data[indextr].sales_document_item }}
          </vs-td>
          <vs-td :data="data[indextr].stock">
            {{ data[indextr].stock }}
          </vs-td>
          <vs-td :data="data[indextr].map_value">
            {{ data[indextr].map_value }}
          </vs-td>
          <vs-td :data="data[indextr].total_value">
            {{ data[indextr].total_value }}
          </vs-td>
          <vs-td :data="data[indextr].currency">
            {{ data[indextr].currency }}
          </vs-td>
          <vs-td :data="data[indextr].less_than_16_days">
            {{ data[indextr].less_than_16_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_1_amount">
            {{ data[indextr].period_1_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_16_and_30_days">
            {{ data[indextr].between_16_and_30_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_2_amount">
            {{ data[indextr].period_2_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_31_and_60_days">
            {{ data[indextr].between_31_and_60_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_3_amount">
            {{ data[indextr].period_3_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_61_and_90_days">
            {{ data[indextr].between_61_and_90_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_4_amount">
            {{ data[indextr].period_4_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_91_and_120_days">
            {{ data[indextr].between_91_and_120_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_5_amount">
            {{ data[indextr].period_5_amount }}
          </vs-td>
          <vs-td :data="data[indextr].between_121_and_150_days">
            {{ data[indextr].between_121_and_150_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_6_amount">
            {{ data[indextr].period_6_amount }}
          </vs-td>
          <vs-td :data="data[indextr].greater_than_150_days">
            {{ data[indextr].greater_than_150_days }}
          </vs-td>
          <vs-td :data="data[indextr].period_7_amount">
            {{ data[indextr].period_7_amount }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";

export default {
  components: {},
  props:{
    territoryIDs: {
      type: Array,
    },
    storageIDs: {
      type: Array,
    },
    skuCodeIDs: {
      type: Array,
    },
    batchIDs: {
      type: Array,
    },
    agingGroupIDs: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table_amount_header: "<  16 days"
    };
  },
  methods: {
    ...mapActions({ getAgingPropductReport: 'reportAgingProduct/getAgingPropductReport' }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getAgingPropductReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          territory: this.territoryIDs,
          storage:this.storageIDs,
          sku_code:this.skuCodeIDs,
          batch:this.batchIDs,
          aging_group:this.agingGroupIDs,
        })
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "ARTRANS_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all") +
        "_";

      if (this.startDocDate || this.endDocDate) {
        fileTitle +=
          "DOC_DATE_" +
          moment(this.startDocDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDocDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startPostingDate || this.endPostingDate) {
        "POSTING_DATE_" +
          moment(this.startPostingDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endPostingDate).format("YYYY-MM-DD") +
          "_";
      }

      if (this.startDueDate || this.endDueDate) {
        "DUE_DATE_" +
          moment(this.startDueDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDueDate).format("YYYY-MM-DD") +
          "_";
      }

      fileTitle +=
        "OPEN_KEY_DATE_" + moment(this.openKeyDate).format("YYYY-MM-DD");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs,
            start_posting_date: this.startPostingDate
              ? moment(this.startPostingDate).format("YYYY-MM-DD")
              : null,
            end_posting_date: this.endPostingDate
              ? moment(this.endPostingDate).format("YYYY-MM-DD")
              : null,
            start_doc_date: this.startDocDate
              ? moment(this.startDocDate).format("YYYY-MM-DD")
              : null,
            end_doc_date: this.endDocDate
              ? moment(this.endDocDate).format("YYYY-MM-DD")
              : null,
            start_due_date: this.startDueDate
              ? moment(this.startDueDate).format("YYYY-MM-DD")
              : null,
            end_due_date: this.endDueDate
              ? moment(this.endDueDate).format("YYYY-MM-DD")
              : null,
            open_key_date: this.openKeyDate
              ? moment(this.openKeyDate).format("YYYY-MM-DD")
              : null,
            file: file,
            title: fileTitle,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startPostingDate() {
    //   this.getData();
    // },
    // endPostingDate() {
    //   this.getData();
    // },
    // startDocDate() {
    //   this.getData();
    // },
    // endDocDate() {
    //   this.getData();
    // },
    // startDueDate() {
    //   this.getData();
    // },
    // endDueDate() {
    //   this.getData();
    // },
    // openKeyDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      listData: (state) => state.reportAgingProduct.tableData,
      totalPage: (state) => state.reportAgingProduct.totalPage
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>